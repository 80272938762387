import React, { ReactNode } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { hashCode } from "../helpers/Helpers";
import ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";

type IconWithTooltipProps = {
  className: string;
  title: string | ReactNode;
  style?: React.CSSProperties;
  color?: string;
  id?: string;
  placement?: "top" | "right" | "bottom" | "left";
  float?: "right" | "left";
  link?: string;
};

export const IconWithTooltip = ({
  className,
  style,
  id,
  title,
  color,
  placement,
  float,
  link,
}: IconWithTooltipProps) => {
  const computedStyle = Object.assign(style || {}, color ? { color } : {});
  const computedId = id
    ? id
    : "icon-with-tooltip-" +
      hashCode(ReactDOMServer.renderToString(<>{title}</>) || "");
  return (
    <>
      {link ? (
        <Link to={link}>
          <span className={float ? "float-" + float : ""}>
            <i
              className={className}
              style={computedStyle}
              id={computedId}
              data-testid="alarm-bell-all-ok"
            />
            <UncontrolledTooltip
              placement={placement ? placement : "top"}
              target={computedId}
              modifiers={{ preventOverflow: { boundariesElement: "window" } }}
              autohide={false}
            >
              {title}
            </UncontrolledTooltip>
          </span>
        </Link>
      ) : (
        <span className={float ? "float-" + float : ""}>
          <i
            className={className}
            style={computedStyle}
            id={computedId}
            data-testid="alarm-bell-all-ok"
          />
          <UncontrolledTooltip
            placement={placement ? placement : "top"}
            target={computedId}
            modifiers={{ preventOverflow: { boundariesElement: "window" } }}
            autohide={false}
          >
            {title}
          </UncontrolledTooltip>
        </span>
      )}
    </>
  );
};
