import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// import i18n (needs to be bundled ;))
import "./i18n";
import {DatePortContext} from "./context/DatePortContext";
import {dateAdapterFactory} from "./utils/AdapterFactories";


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
        <DatePortContext.Provider value={dateAdapterFactory()}>
            <App />
        </DatePortContext.Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
