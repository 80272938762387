import React, { Component } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AWS from "aws-sdk/global";
import EnvVars from "./helpers/EnvVars";
import FullScreenLoader from "./components/FullScreenLoader";
import { DroopleCache } from "./helpers/DroopleCache";
// import DroopleAppAwsRum from "./utils/DroopleAppAwsRum";

export const DEFAULT_LOCALE = "en-US";
// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const Login = React.lazy(() => import("./views/Auth/Login"));
const Logout = React.lazy(() => import("./views/Auth/Logout"));
const Verify = React.lazy(() => import("./views/Auth/Verify"));
const Register = React.lazy(() => import("./views/Auth/Register"));
const SSOLogin = React.lazy(() => import("./views/Auth/SSOLogin"));
const ResetPassword = React.lazy(() => import("./views/Auth/ResetPassword"));
const Page404 = React.lazy(() => import("./views/Page404"));
const Page500 = React.lazy(() => import("./views/Page500"));
const PublicAwarenessScreen = React.lazy(
  () => import("./views/PublicAwarenessScreen/PublicAwarenessScreen")
);

AWS.config.update({ region: EnvVars.AWS_REGION });

DroopleCache.init();

// if (EnvVars.USER_MONITORING_ENABLED) {
//   DroopleAppAwsRum.init();
// }

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={<FullScreenLoader />}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/verify" component={Verify} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/sso-login" component={SSOLogin} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/404" component={Page404} />
            <Route exact path="/500" component={Page500} />
            <Route
              path="/public_awareness_screen/:uuid"
              component={PublicAwarenessScreen}
            />
            <Route path="/" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
