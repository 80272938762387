import React, { ReactNode } from "react";
import convertUnits from "convert-units";
import { HelpTooltip } from "../components/HelpTooltip";
import {
  AssetParams,
  Client,
  ConvertibleUnit,
  Device,
  DeviceModelID,
  Params,
  ParamsWithDefault,
  TelemetryDataPoint,
} from "../components/Interfaces";
import Chance from "chance";
import moment from "moment-timezone";
import { History } from "history";
import i18next, { TFunction } from "i18next";
import { ToastOptions } from "react-toastify/dist/types";
import { i18NextNamespaces } from "../i18n";

export const getSizesPerPage = (t: TFunction) => [
  { text: t("pagination:itemsPerPage", { count: 15 }), value: 15 },
  { text: t("pagination:itemsPerPage", { count: 50 }), value: 50 },
  { text: t("pagination:itemsPerPage", { count: 100 }), value: 100 },
  { text: t("pagination:allItems"), value: 1000 }, // should be reasonably high - it doesn't make sense to display more than that.
];

export const TOAST_OPTIONS: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function getUnit(
  unit: ConvertibleUnit | string
):
  | "C"
  | "F"
  | "m3"
  | "l"
  | "gal"
  | "Pa"
  | "kPa"
  | "bar"
  | "psi"
  | "kg"
  | "g"
  | "lb" {
  switch (unit) {
    case "degree_celsius":
      return "C";
    case "degree_fahrenheit":
      return "F";
    case "m3":
      return "m3";
    case "liter":
      return "l";
    case "us_gallon":
      return "gal";
    case "imperial_gallon":
      return "gal";
    case "pascal":
      return "Pa";
    case "kilo_pascal":
      return "kPa";
    case "bar":
      return "bar";
    case "psi":
      return "psi";
    case "kg":
      return "kg";
    case "g":
      return "g";
    case "lb":
      return "lb";
    default:
      throw Error("Unsupported unit " + unit);
  }
}

export function getUserFriendlyUnit(
  unit: ConvertibleUnit,
  t: TFunction
): string {
  switch (unit) {
    case "degree_celsius":
    case "degree_fahrenheit":
    case "m3":
    case "liter":
    case "us_gallon":
    case "imperial_gallon":
    case "pascal":
    case "kilo_pascal":
    case "bar":
    case "psi":
    case "kg":
    case "g":
    case "lb":
      return t(`${i18NextNamespaces.UNITS}:${unit}`);
    default:
      return unit;
  }
}

export function formatValueWithFriendlyUnit(
  value: number,
  from: ConvertibleUnit,
  to: ConvertibleUnit,
  t: TFunction
): string {
  return `${convert(value, from, to)} ${getUserFriendlyUnit(to, t)}`;
}

export function formatValueWithShortUnit(
  value: number,
  from: ConvertibleUnit,
  to: ConvertibleUnit
): string {
  return `${convert(value, from, to)}°${getUnit(to)}`;
}

export function round2(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

/**
 * Rounds to the nearest integer
 */
export function round(num: number) {
  return Math.round(num + Number.EPSILON);
}

export function roundN(num: number, round: number) {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, round)) /
    Math.pow(10, round)
  );
}

export const formatNumber = (val: number, roundN: number = 0): string => {
  return new Intl.NumberFormat(i18next.resolvedLanguage, {
    notation: "standard",
    maximumFractionDigits: roundN,
  }).format(val);
};

/**
 * Converts and rounds a numeric value from one unit to the other.
 * @param value the initial value
 * @param from the unit of the initial value
 * @param to the unit to convert to
 * @param round the number of decimals to round to
 */
export function convert(
  value: number | undefined,
  from: ConvertibleUnit,
  to: ConvertibleUnit | undefined | null,
  round: number | null = 2
): number {
  if (!to) {
    return round === null ? value || 0 : roundN(value ?? 0, round);
  }
  // TODO use Math.js unit API instead, which is much less of a hack than this below... https://mathjs.org/docs/datatypes/units.html
  let factor = 1;
  const imperial_to_us_factor = 1.20095;
  const us_to_imperial_factor = 0.832674;
  if (from === "imperial_gallon") {
    factor = factor * imperial_to_us_factor;
  }
  if (to === "imperial_gallon") {
    factor = factor * us_to_imperial_factor;
  }
  const converted =
    convertUnits(value || 0)
      .from(getUnit(from))
      .to(getUnit(to)) * factor;
  if (round === null) {
    return converted;
  }
  return roundN(converted, round);
}

/**
 * Converts a value per unit to another unit.
 * No rounding is done.
 *
 * @param value: The value to convert.
 * @param from: The unit to convert from.
 * @param to: The unit to convert to.
 */
export function convertPerUnit(
  value: number,
  from: ConvertibleUnit,
  to: ConvertibleUnit
): number {
  const factor = 1 / convert(1, from, to, null);
  return value * factor;
}

/**
 * Format a device ID to resemble AA:BB:CC:DD.
 * @param forcePairs force grouping the characters into chunks of two. Disabled by default.
 */
export function decodeDevId(dev_id?: string | null, forcePairs?: boolean) {
  let result = (dev_id || "").toUpperCase().replace(/-/g, ":");
  if (forcePairs) {
    result = (result.replace(/:/g, "").match(/.{1,2}/g) || []).join(":");
  }
  return result;
}
/**
 * Format a device ID to resemble aa-bb-cc-dd.
 *  @param forcePairs force grouping the characters into chunks of two. Disabled by default.
 */
export function encodeDevId(dev_id?: string | null, forcePairs?: boolean) {
  let result = (dev_id || "").toLowerCase().replace(/:/g, "-");
  if (forcePairs) {
    result = (result.replace(/-/g, "").match(/.{1,2}/g) || []).join("-");
  }
  return result;
}

/**
 * Generates a deterministic hash from a string
 * @param s the string
 */
export function hashCode(s: string) {
  let h = 0;
  for (let i = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return h;
}

export function columnHeaderWithHelp(help: ReactNode) {
  return (column: any, _colIndex: any, components: any) => (
    <span style={{ display: "flex" }}>
      {column.text || ""} <HelpTooltip>{help}</HelpTooltip>{" "}
      {components.sortElement}
      {components.filterElement}
    </span>
  );
}

export function randomRGB(seed: string | number): string {
  const chance = Chance(seed);
  const rgb = [1, 1, 1].map((_) => chance.integer({ min: 0, max: 255 }));
  // Exclude colors that won't be visible on white background
  // Check if the color is too light
  if (rgb[0] + rgb[1] + rgb[2] > 510) {
    // If it's too light, invert the color
    rgb[0] = 255 - rgb[0];
    rgb[1] = 255 - rgb[1];
    rgb[2] = 255 - rgb[2];
  }
  return rgb.join(",");
}

export const onlyUniqueFilter = (value: any, index: number, self: any[]) =>
  self.indexOf(value) === index;

export const displayDurationInHoursMinSec = (duration: moment.Duration) => {
  const secondsTotal = duration.asSeconds();
  const hours = Math.floor(secondsTotal / 3600);
  const minutes = Math.floor((secondsTotal - hours * 3600) / 60);
  const seconds = round(secondsTotal - hours * 3600 - minutes * 60);
  return `${hours ? hours + "h " : ""}${
    hours || minutes ? minutes + "min " : ""
  }${seconds}sec`;
};

export function sortByUTCTimeASC(
  a: { utc_time: string },
  b: { utc_time: string }
): number {
  return moment(a.utc_time).diff(moment(b.utc_time));
}
export function sortByUTCTimeDESC(
  a: { utc_time: string },
  b: { utc_time: string }
): number {
  return -moment(a.utc_time).diff(moment(b.utc_time));
}

export function isILinkModel(device: Partial<Device>): boolean {
  const model_id = device.model_id ? device.model_id : device.model?.id;
  switch (model_id) {
    case "droople_v1":
    case "droople_v2":
    case "droople_v3":
    case "droople_v4":
    case "droople_v4b":
    case "smarthub":
      return true;
    case "erseye":
    case "tabs_motion_sensor":
    case "gwf_flow_meter":
    case "door_sensor":
      return false;
    case undefined:
      return false;
  }
}

export function isDefined(obj: any) {
  return obj !== null && obj !== undefined;
}

/**
 * Returns **false** if object is `null` or `undefined`, **true** otherwise.
 * Unlike isDefined, isStrictlyDefined tells the compiler that the object is defined.
 */
export function isStrictlyDefined<
  T extends object | string | number | bigint | boolean | symbol
>(obj: T | undefined | null): obj is T {
  return obj !== null && obj !== undefined;
}

export function downloadCSV<T extends { [k: string]: any }>(
  filename: string,
  arr: T[]
) {
  if (arr.length === 0) {
    return;
  }
  const keys = arr.flatMap((row) => Object.keys(row)).filter(onlyUniqueFilter);
  const rows = arr
    .map((obj) =>
      keys
        .map((k) => {
          let row =
            obj[k] !== undefined ? ("" + obj[k]).replace(/"/g, '""') : "";
          if (row.search(/("|,|\n)/g) >= 0) {
            row = '"' + row + '"';
          }
          return row;
        })
        .join(",")
    )
    .join("\n");
  const cleanKeys = keys
    .map((k) => {
      let row = k.replace(/"/g, '""');
      if (row.search(/("|,|\n)/g) >= 0) {
        row = '"' + row + '"';
      }
      return row;
    })
    .join(",");

  // const csvContent = "data:text/csv;charset=utf-8," + keys.join(',') + "\n" + rows;
  const csvFile = cleanKeys + "\n" + rows;

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

/**
 * Finds the first element that is of the given data_type
 * @param tdp
 * @param data_type
 * @returns
 */
export function findDataType(
  tdp: TelemetryDataPoint[] | undefined,
  data_type: string
) {
  return tdp ? tdp.filter((_) => _.data_type === data_type)[0] : undefined;
}
export function findDataTypeWithDelta(
  tdp: TelemetryDataPoint[] | undefined,
  data_type: string
) {
  return tdp
    ? tdp.filter((_) => _.data_type === data_type && _.delta)[0]
    : undefined;
}

export type StringMap<T> = { [key: string]: T };

/**
 * Creates a map from the object key to the object itself
 * @param arr the array of objects
 * @param getKey the function to get the key from the object
 */
export function buildStringMap<T>(
  arr: T[],
  getKey: (obj: T) => string
): StringMap<T> {
  const map: { [key: string]: T } = {};
  arr.forEach((obj) => (map[getKey(obj)] = obj));
  return map;
}

export function buildStringArrayMap<T>(
  arr: T[],
  getKey: (obj: T) => string
): StringMap<T[]> {
  const map: { [key: string]: T[] } = {};
  arr.forEach((obj) => {
    const keyVal = getKey(obj);
    if (isDefined(keyVal)) {
      if (!map[keyVal]) {
        map[keyVal] = [obj];
      } else {
        map[keyVal].push(obj);
      }
    }
  });
  return map;
}

export type IntMap<T> = { [key: number]: T | undefined };

export function buildIntMap<T>(
  arr: T[],
  getKey: (obj: T) => number | null | undefined
): IntMap<T> {
  const map: { [key: number]: T } = {};
  arr.forEach((obj) => {
    const k = getKey(obj);
    if (isDefined(k)) {
      map[k as number] = obj;
    }
  });
  return map;
}
export function buildIntArrayMap<T>(
  arr: T[],
  getKey: (obj: T) => number
): IntMap<T[]> {
  const map: { [key: number]: T[] } = {};
  arr.forEach((obj) => {
    const keyVal = getKey(obj);
    if (isDefined(keyVal)) {
      if (!map[keyVal]) {
        map[keyVal] = [obj];
      } else {
        map[keyVal].push(obj);
      }
    }
  });
  return map;
}

export function sourceFormatter(source: string) {
  switch (source) {
    case "BrowanMotionSensorPIRConverter":
      return "TTNv3";
    case "ElsysPayloadConverter":
      return "TTNv2";
    case "ILinkV2PayloadConverter":
      return "TTNv2";
    case "ILinkV3BAWSPayloadConverter":
      return "AWS";
    case "ILinkV3BSwisscomPayloadConverter":
      return "Swisscom";
    case "ILinkV3BTTNV3PayloadConverter":
      return "TTNv3";
    case "ILinkV3TTNV3PayloadConverter":
      return "TTNv3";
    case "ILinkV2TTNV3PayloadConverter":
      return "TTNv3";
    case "ILinkV3PayloadConverter":
      return "TTNv2";
    case "GWFFlowMeterConverter":
      return "TTNv3";
    case "ILinkV4TTNV3LiquisensPayloadConverter":
      return "TTNv3";
    default:
      return source;
  }
}

/**
 * Merge all available parameters from the source entity, fetching the client and its parent parameters as well, but preferring parameters closest to the source.
 * @param source
 * @returns Params
 */
export function getParams(
  source?: {
    params?: Params | AssetParams | null;
    client?: Client | null;
    parent?: Client | null;
  } | null
): ParamsWithDefault {
  const params: ParamsWithDefault = {
    awareness_screen_feature: true,
    activities_feature: false,
    water_dispensers_feature: true,
    order_co2_bottles: false,
    everclean_feature: false,
    hygiene_screen_feature: false,
    receive_children_notifications: false,
    interrupted_flow_alarms_threshold_hours: 0,
  };
  if (source?.parent) {
    // source is a client with parent
    Object.assign(params, getParams(source.parent));
  }
  if (source?.client) {
    // source is a user or other entity belonging to a client
    Object.assign(params, getParams(source.client));
  }
  if (source?.params) {
    // source is a client or a user
    Object.assign(params, source.params);
  }
  return params;
}

/**
 * Better alternative to moment.max that discards undefined and null values and returns undefined in case of empty array.
 * @param moments
 */
export function momentMax(
  moments: (string | moment.Moment | null | undefined)[]
) {
  const valid_moments = moments
    .map((_) => (typeof _ === "string" ? moment(_) : _))
    .filter((_) => _ && _.isValid()) as moment.Moment[];
  return valid_moments.length ? moment.max(valid_moments) : undefined;
}

/**
 * Better alternative to moment.min that discards undefined and null values and returns undefined in case of empty array.
 * @param moments
 */
export function momentMin(
  moments: (string | moment.Moment | null | undefined)[]
) {
  const valid_moments = moments
    .map((_) => (typeof _ === "string" ? moment(_) : _))
    .filter((_) => _ && _.isValid()) as moment.Moment[];
  return valid_moments.length ? moment.min(valid_moments) : undefined;
}

export function copyToClipboard(title: string, text: string) {
  window.prompt(title + "\nCopy to clipboard: Ctrl+C, Enter", text);
}

export function pluralize(count: number, unit: string) {
  if (count !== 1) {
    return count + unit + "s";
  } else {
    return count + unit;
  }
}

export function nl2br(str: string): ReactNode {
  const lines = str.split(/(?:\r\n|\r|\n)/g);
  return lines.map((line, index) => (
    <>
      {line}
      {index + 1 < lines.length ? <br /> : null}
    </>
  ));
}
export const displayMinutesInHoursMin = (inputMinutes: number) => {
  const hours = Math.floor(inputMinutes / 60);
  const minutes = inputMinutes % 60;
  return `${hours ? hours + "h" : ""}${
    minutes ? (hours ? " " : "") + minutes + "min" : ""
  }`;
};

/**
 * Joins an array with commas, but use "and" for the final element to make it nicer to read.
 *
 * Example: ['a','b','c'] => "a, b and c"
 *
 * Source: https://stackoverflow.com/a/16251861
 */
export function joinWithCommas(arr: string[]): string {
  return [arr.slice(0, -1).join(", "), arr.slice(-1)[0]].join(
    arr.length < 2 ? "" : " and "
  );
}

export function updateQueryParam(
  key: string,
  value: string | undefined,
  history: History,
  pushOrReplace: "push" | "replace" = "push"
) {
  const url = new URL(window.location.href);
  if (isStrictlyDefined(value)) {
    url.searchParams.set(key, value);
  } else {
    url.searchParams.delete(key);
  }
  if (pushOrReplace === "push") {
    history.push(url.pathname + "?" + url.searchParams.toString());
  } else {
    history.replace(url.pathname + "?" + url.searchParams.toString());
  }
}

/**
 * Identify the language within a locale definition.
 *
 * Nullish values are returned as themselves.
 *
 * Only supported languages are returned. Other languages will return `undefined`
 */
export function getLanguage(
  locale: string | undefined | null
): "en" | "de" | "fr" | "ja" | undefined | null {
  if (!isStrictlyDefined(locale)) {
    return locale;
  }
  const normalized = locale.replace(/_/g, "-");
  const language = normalized.split("-")[0].toLowerCase();
  switch (language) {
    case "en":
    case "de":
    case "fr":
    case "ja":
      return language;
    default:
      return undefined;
  }
}

/**
 * Add a query params string in the form of `var1=val1&var2=val2` to a base URL with
 * the right junction (? or &) depending on whether the base already has query params
 *
 * @param base a base part of a URL
 * @param params a sequence of parameters in query string format
 * @returns `base&params`or `base?params` depending on what is right, or the base if params is an empty string
 */
export function appendParamsToURL(base: string, params: string) {
  if (!params) {
    return base;
  }
  const paramsJunction = base.indexOf("?") === -1 ? "?" : "&";
  return base + paramsJunction + params;
}
export function csvCellDataFormatter(cell?: number | string | null) {
  if (!isStrictlyDefined(cell)) {
    return "";
  } else if (typeof cell === "number") {
    return Number(cell).toLocaleString();
  } else {
    return cell;
  }
}
export function isStrictlyDefinedAndPositive(
  value?: number | null
): value is number {
  return value !== null && value !== undefined && value >= 0;
}

// source: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function colorConvertHexToRgb(hex: string) {
  if (hex.length === 7) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      return { r, g, b };
    }
  } else if (hex.length === 4) {
    /**
     * The format of an RGB value in hexadecimal notation is a '#' immediately followed by either three or six hexadecimal characters.
     * The three-digit RGB notation (#rgb) is converted into six-digit form (#rrggbb) by replicating digits, not by adding zeros.
     * For example, #fb0 expands to #ffbb00. This ensures that white (#ffffff) can be specified with the short notation (#fff) and removes any dependencies on the color depth of the display.
     * Source: https://www.w3.org/TR/CSS2/syndata.html#value-def-color
     */
    const result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
    if (result) {
      const r = parseInt("" + result[1] + result[1], 16);
      const g = parseInt("" + result[2] + result[2], 16);
      const b = parseInt("" + result[3] + result[3], 16);
      return { r, g, b };
    }
  }
  console.error(`Cannot convert hex color ${hex} to RGB`);
  return { r: 0, g: 0, b: 0 };
}

export function areTimezonesDifferent(timezoneA: string, timezoneB: string) {
  return (
    moment.tz.zone(timezoneA)?.utcOffset(moment().valueOf()) !==
    moment.tz.zone(timezoneB)?.utcOffset(moment().valueOf())
  );
}

//guarantees "undefined" if param is not a number or an array of numbers
export function min(
  param: number | null | undefined | (number | string)[]
): undefined | number {
  if (param === null || param === undefined) return undefined;
  if (typeof param === "number") return param;
  if (Array.isArray(param)) {
    if (param.length === 0) return undefined;
    let onlyNumbers = param.reduce(function (result: boolean, val: any) {
      return result && typeof val === "number";
    }, true);
    if (onlyNumbers) {
      const paramValues = param as number[];
      return Math.min(...paramValues);
    }
  }
  return undefined;
}

//guarantees "undefined" if param is not a number or an array of numbers
export function max(
  param: number | null | undefined | (number | string)[]
): undefined | number {
  if (param === null || param === undefined) return undefined;
  if (typeof param === "number") return param;
  if (Array.isArray(param)) {
    if (param.length === 0) return undefined;
    let onlyNumbers = param.reduce(function (result: boolean, val: any) {
      return result && typeof val === "number";
    }, true);
    if (onlyNumbers) {
      const paramValues = param as number[];
      return Math.max(...paramValues);
    }
  }
  return undefined;
}
export function hasSaierSensor(devices?: (Device | null | undefined)[] | null) {
  const hasSaierText = "Flow & Temp";
  return !!devices?.some((device) =>
    device?.sensors.some((sensor) => sensor.sensor?.name.includes(hasSaierText))
  );
}

export function languageToFlagCode(lang: string) {
  switch (lang) {
    case "en":
      return "gb";
    default:
      return lang;
  }
}

export function languageCodeToTranslatedLanguageForDisplay(
  lang: string,
  t: TFunction
) {
  switch (lang) {
    case "en":
      return t(`${i18NextNamespaces.COMMON}:english`);
    case "fr":
      return t(`${i18NextNamespaces.COMMON}:french`);
    case "de":
      return t(`${i18NextNamespaces.COMMON}:german`);
    default:
      return lang;
  }
}

export function signed(number: number): string {
  return (number >= 0 ? "+" : "") + number;
}

export type UnitName =
  | "volume_units"
  | "temperature_units"
  | "pressure_units"
  | "mass_units";

export function getUnitPossibleValues(name: UnitName, t: TFunction) {
  switch (name) {
    case "mass_units":
      return {
        kg: "kg",
        lb: "lb",
      };
    case "pressure_units":
      return {
        pascal: "Pascal",
        kilo_pascal: "Kilopascal",
        bar: "Bar",
        psi: "Psi",
      };
    case "temperature_units":
      return {
        degree_celsius: "Celsius",
        degree_fahrenheit: "Fahrenheit",
      };
    case "volume_units":
      return {
        m3: t(`${i18NextNamespaces.COMMON}:cubicMeter`),
        liter: t(`${i18NextNamespaces.COMMON}:liter`),
        us_gallon: t(`${i18NextNamespaces.COMMON}:usGallon`),
        imperial_gallon: t(`${i18NextNamespaces.COMMON}:imperialGallon`),
      };
  }
}

export function canCallFirmwareService(modelID: DeviceModelID) {
  return !["door_sensor"].includes(modelID);
}
