import React, { FunctionComponent } from "react";
import { IconWithTooltip } from "./IconWithTooltip";

type HelpTooltipProps = { id?: string; float?: "right" | "left" };

export const HelpTooltip: FunctionComponent<HelpTooltipProps> = ({
  id,
  float,
  children,
}) => {
  return (
    <IconWithTooltip
      id={id}
      className={"far fa-question-circle mx-1"}
      float={float}
      title={children}
    />
  );
};
