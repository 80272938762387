import { DatePort } from "./DatePort";

export class StubDateAdapter implements DatePort {
  public capturedUtcTimes: string[] = [];
  constructor(private readonly formattedNow: string) {}

  formatLatestDate(utcTimes: string[]): string {
    this.capturedUtcTimes = this.capturedUtcTimes.concat(utcTimes);
    return this.formattedNow;
  }

  getNowIsoString(): string {
    return this.formattedNow;
  }
}
