import moment from "moment-timezone";
import { DatePort } from "./DatePort";

export class DateAdapter implements DatePort {
  formatLatestDate(utcTimes: string[]): string {
    return utcTimes.length > 0
      ? moment(utcTimes[utcTimes.length - 1]).format()
      : "N/A";
  }

  getNowIsoString(): string {
    return new Date().toISOString();
  }
}
